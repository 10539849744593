/* global FormData, location */

import TokenStore from './token-store';
import isProduction from 'lib/is-production';

// More info regarding allowed characters in a file name: https://helpdesk.bangthetable.com/en/articles/1566185-troubleshoot-unable-to-save-the-uploaded-file
export const INVALID_EHQ_FILE_NAME_CHARACTERS = /[#%&{}\\<>*?/$!'":@+\[\]=,\/;\|\^~]/gm;

export const sanitizedFileName = (name) => {
  return name.replace(INVALID_EHQ_FILE_NAME_CHARACTERS, '');
}

export const sanitizedFile = (file) => {
  return new File([file], sanitizedFileName(file.name), { type: file.type })
}

const isFileNameInvalid = (file) => {
  return INVALID_EHQ_FILE_NAME_CHARACTERS.test(file.name)
}

export default class FileUpload {
  static async perform(file) {
    if (!file) {
      throw new Error('Missing required argument: file');
    }

    const fd = new FormData();
    fd.append('data[attributes][file]', isFileNameInvalid(file) ? sanitizedFile(file) : file);

    const headers = {};

    // Add headers only in development
    if (!isProduction) {
      headers['X-API-Token'] = `Bearer ${TokenStore.apiToken}`;
    }

    const response = await fetch('/api/v2/file_uploads', {
      method: 'POST',
      body: fd,
      headers, // Use the headers object
    });

    if (response.ok) {
      const json = await response.json();
      const url = json.data.attributes.url;

      if (process.env.NODE_ENV === 'production') {
        return url;
      }

      return location.origin + url;
    }

    throw response;
  }
}
